var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.TextDetail && Object.keys(_vm.TextDetail).length),expression:"TextDetail && Object.keys(TextDetail).length"}],staticClass:"text-detail"},[(_vm.banners && _vm.banners.length)?_c('TextMaterialsSlider',{attrs:{"banners":_vm.banners,"title":_vm.TextDetail.name,"color":'#fff'}}):_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__title",domProps:{"innerHTML":_vm._s(_vm.TextDetail.name)}})]),_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Библиотека', link: { name: 'Library' } },
        {
          name: 'Публикации',
          link: {
            name: 'TextMaterials',
          },
        },
        {
          name: _vm.TextDetail.name,
        } ]}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__row mt-4"},[_c('div',[_c('div',{staticClass:"material__tags-wrap"},[_c('div',{staticClass:"material__tags"},[(_vm.TextDetail.type)?_c('div',{staticClass:"material__tag"},[_vm._v(" "+_vm._s(_vm.TextDetail.type.name)+" ")]):_vm._e(),_vm._l((_vm.TextDetail.nosology),function(nosology){return _c('div',{key:("nos" + (nosology.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(nosology.title)}})}),_vm._l((_vm.TextDetail.disease),function(disease){return _c('div',{key:("dis" + (disease.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(disease.title)}})}),(_vm.TextDetail.is_new && false)?_c('div',{staticClass:"material__tag material__tag_new"},[_vm._v(" Новинка ")]):_vm._e()],2),(!_vm.$route.query.access)?_c('Favorite',{staticClass:"large",attrs:{"watchLater":_vm.TextDetail.watch_later,"favorite":_vm.TextDetail.favorite},on:{"watchLater":function($event){return _vm.watchLater(
                  _vm.TextDetail.watch_later ? 'DELETE' : 'POST',
                  _vm.TextDetail.slug,
                  null,
                  true
                )},"favorite":function($event){return _vm.favorite(
                  _vm.TextDetail.favorite ? 'DELETE' : 'POST',
                  _vm.TextDetail.slug,
                  null,
                  true
                )}}}):_vm._e()],1),(_vm.TextDetail.file)?_c('div',{staticClass:"material__pdf-preview"},[_c('img',{attrs:{"src":_vm.TextDetail.pdf_preview}}),_c('a',{staticClass:"button button_pink px-6 mx-auto mb-4",attrs:{"href":_vm.TextDetail.file.url,"target":"_blank"}},[_vm._v(" Скачать "),_c('svg',{staticClass:"ml-2",attrs:{"width":"20","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z","fill":"currentColor"}})])])]):_vm._e(),(
              _vm.TextDetail.content &&
              _vm.TextDetail.presentations &&
              !_vm.TextDetail.presentations.length
            )?_c('TextDetailBody',{attrs:{"content":_vm.TextDetail.content}}):_vm._e(),(_vm.TextDetail.presentations && _vm.TextDetail.presentations.length)?_c('div',{staticClass:"text-detail__body"},_vm._l((_vm.TextDetail.presentations),function(presentation,ind){return _c('Presentations',{key:ind,attrs:{"color":'#830051',"autoplay":'false',"Slides":presentation.pages}})}),1):_vm._e()],1),_c('div',{staticClass:"sticky"},[(_vm.asideItems && _vm.asideItems.length)?_c('RightAside',{attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"nosologyClick":_vm.nosologyClick}}):_vm._e()],1)]),(_vm.TextDetail.survey && _vm.filteredSurveyQuestions.questions.length)?_c('div',{staticClass:"button button_pink mt-8",on:{"click":_vm.openSurvey}},[_vm._v(" Пройти опрос ")]):_vm._e(),_c('div',{staticClass:"text-detail__buttons"},[_c('router-link',{staticClass:"text-detail__load button button_pink",attrs:{"to":{ name: 'TextMaterials' }}},[_c('span',{staticClass:"mr-2 mt-n-1"},[_c('svg',{attrs:{"width":"11","height":"18","viewBox":"0 0 11 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9 1.5L1.5 9L9 16.5","stroke":"white","stroke-width":"1.5","stroke-linecap":"square"}})])]),_vm._v(" Назад ")]),_c('div',{staticClass:"text-detail__up button button_empty-pink",on:{"click":function($event){return _vm.toTop()}}},[_vm._v(" К началу страницы "),_c('span',{staticClass:"ml-2"},[_c('svg',{attrs:{"width":"18","height":"10","viewBox":"0 0 18 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.25 8.75012L8.75 1.25012L16.25 8.75012","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])])])],1)]),(
        _vm.TextDetail.nosology &&
        _vm.TextDetail.nosology.length &&
        _vm.sliderItems.length
      )?_c('LibrarySlider',{attrs:{"nosology":_vm.TextDetail.nosology[0],"items":_vm.sliderItems,"page":'TextDetail'},on:{"clickMaterials":_vm.clickMaterials,"toNosol":_vm.toNosol}}):_vm._e(),(_vm.survey.opened && _vm.filteredSurveyQuestions.questions.length)?_c('PopUpDefault',{staticClass:"profiling-modal__survey",on:{"close":_vm.closeSurvey}},[_c('LibrarySurvey',{attrs:{"content_type":'text',"material_slug":_vm.TextDetail.slug,"survey":_vm.filteredSurveyQuestions},on:{"close":_vm.closeSurvey,"closeAfterSend":_vm.closeAfterSend}})],1):_vm._e()],1),(_vm.TextDetail === 404)?_c('div',[_c('Choch',{staticClass:"inPage"})],1):_vm._e(),(_vm.TextDetail === false)?_c('div',[_c('Error',{staticClass:"inPage"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }